const layout = {
  APP_MAX_WIDTH: 480,
  APP_MAX_HEIGHT: 1080,
  APP_MENU_WIDTH: 260,
  APP_NAVBAR_HEIGHT: 60,

  PARTNER_HEADER_HEIGHT: 64,
  EMBEDDABLE_PARTNER_DEFAULT_HEIGHT: 0,
  BREAKPOINT_MEDIUM: 540, // APP_MAX_WIDTH + 80
  BREAKPOINT_LARGE: 820, // APP_MAX_WIDTH + APP_MENU_WIDTH + 80

  GUTTER_SIZE: 20,

  DEFAULT_SHADOW_SMALL: '0px 2px 5px rgba(0, 40, 69, 0.18)',
  DEFAULT_SHADOW_MEDIUM: '0px 4px 10px rgba(0, 40, 69, 0.13)',
  DEFAULT_SHADOW_LARGE: '0px 6px 19px rgba(0, 40, 69, 0.12)',
  DEFAULT_SHADOW_BOTTOM_ALIGNED:
    '0px -5px 18px rgba(21, 61, 87, 0.08), 0px -1px 5px rgba(21, 61, 87, 0.07)',

  BELOW_ALL_ZINDEX: -1,
  BASIC_ZINDEX: 1,
  FOOTER_ZINDEX: 2,
  HEADER_ZINDEX: 4,
  PARTNER_HEADER_AND_APP_MENU_OVERLAY_ZINDEX: 5,
  GLOBAL_LOADING_INDICATOR_AND_TOGGLE_ZINDEX: 7,
  MOBILE_APP_MENU_ZINDEX: 8,
  VIDEO_MEETING_ZINDEX: 9,
  MODAL_ZINDEX: 10,
};

export default layout;
