/** @jsx jsx */
import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CSSObject, jsx } from '@emotion/core';
import useNavigation from '../hooks/useNavigation';
import Screen from './screen/screen';
import {
  ScreenContextProvider,
  IScreenSource,
} from '../contexts/screenContext';
import {
  ISearchSource,
  SearchContextProvider,
} from '../contexts/searchContext';
import Flex from './flex/flex';
import { IFlexSource, FlexContextProvider } from '../contexts/flexContext';
import Navbar from '../components/navbar';
import { cancelAllRequests } from '../utils/request';
import { ILoginSource, LoginContextProvider } from '../contexts/loginContext';
import { LanguageSelectorContextProvider } from '../contexts/languageSelectorContext';
import { DebugContextProvider } from '../contexts/debugContext';
import useInternalUrl from '../hooks/useInternalUrl';
import layout from '../constants/layout';
import useTheme from '../hooks/useTheme';
import currentPartner from '../constants/partners';
import { ViewContext } from '../contexts/viewContext';

interface IBaseView {
  title?: string;
  hideAppMenu?: boolean;
  preventBack?: boolean;

  constrainHeight?: number;
  autoHeight?: boolean;

  isTabRoot?: boolean;
  isModalRoot?: boolean;
  isModalDescendant?: boolean;
  isSpringDetails?: boolean;
}

interface IScreenView extends IBaseView {
  type: 'SCREEN';
  source: IScreenSource;
}

interface ISearchScreenView extends IBaseView {
  type: 'SEARCH_SCREEN';
  source: ISearchSource;
}

interface IFlexView extends IBaseView {
  type: 'FLEX';
  source: IFlexSource;
}

interface ILoginView extends IBaseView {
  type: 'LOGIN';
  source?: ILoginSource;
}

interface ILanguageSelectorView extends IBaseView {
  type: 'LANGUAGE_SELECTOR';
}

interface IDebugView extends IBaseView {
  type: 'DEBUG';
}

export type IView =
  | IScreenView
  | ISearchScreenView
  | IFlexView
  | IDebugView
  | ILoginView
  | ILanguageSelectorView;

interface IViewProps {
  view: IView;
}

const View = React.memo(({ view }: IViewProps) => {
  const EMBEDABBLE_PARTNERS = ['lime', 'numan'];

  const { setHeaderElement, setMainElement, setFooterElement } =
    useContext(ViewContext);
  const [componentHasRendered, setComponentHasRendered] = useState(false);

  const { color } = useTheme();
  const { getCurrentView, getPostNavigationOptions, getLocationKey } =
    useNavigation();
  const handleInternalUrl = useInternalUrl();

  const headerElRef = useRef<HTMLElement | null>();
  const mainElRef = useRef<HTMLElement | null>();
  const footerElRef = useRef<HTMLElement | null>();
  const currentView = getCurrentView();

  const getPartnerHeaderHeight = () => {
    if (!!currentPartner && EMBEDABBLE_PARTNERS.includes(currentPartner.id)) {
      return layout.EMBEDDABLE_PARTNER_DEFAULT_HEIGHT;
    }
    if (!!currentPartner && !EMBEDABBLE_PARTNERS.includes(currentPartner.id)) {
      return layout.PARTNER_HEADER_HEIGHT;
    }
    return 0;
  };

  const getViewComponent = () => {
    switch (view.type) {
      case 'SCREEN':
        return (
          <ScreenContextProvider routeSource={view.source}>
            <Navbar />
            <Screen />
          </ScreenContextProvider>
        );
      case 'SEARCH_SCREEN':
        return (
          <ScreenContextProvider key={getLocationKey()}>
            <SearchContextProvider routeSource={view.source} />
            <Screen />
          </ScreenContextProvider>
        );
      case 'FLEX':
        return (
          <FlexContextProvider routeSource={view.source}>
            <Flex />
          </FlexContextProvider>
        );
      case 'DEBUG':
        return <DebugContextProvider />;
      case 'LOGIN':
        return <LoginContextProvider routeSource={view.source} />;
      case 'LANGUAGE_SELECTOR':
        return <LanguageSelectorContextProvider />;
      default:
        return null;
    }
  };

  // Cancel all ongoing requests upon navigation.
  // @TODO: Look into tagging requests to be able to only cancel the ones
  // that it might apply to.
  useLayoutEffect(() => {
    const { persistRequests } = getPostNavigationOptions();

    if (!persistRequests) {
      cancelAllRequests();
    }
  }, [view, getPostNavigationOptions]);

  // Handle any potential post-navigation navigation.
  useLayoutEffect(() => {
    const { internalUrl } = getPostNavigationOptions();

    if (internalUrl) {
      handleInternalUrl(internalUrl);
    }
  }, [view, handleInternalUrl, getPostNavigationOptions]);

  // Delay rendering the children until after the frame has rendered,
  // as content is using the header and footer elements.
  useEffect(() => setComponentHasRendered(true), []);

  useEffect(() => {
    if (
      componentHasRendered &&
      headerElRef.current &&
      mainElRef.current &&
      footerElRef.current
    ) {
      setHeaderElement(headerElRef.current);
      setMainElement(mainElRef.current);
      setFooterElement(footerElRef.current);
    }
  }, [
    componentHasRendered,
    setHeaderElement,
    setMainElement,
    setFooterElement,
  ]);

  // Focus the content wrapper whenever the view changes.
  useEffect(() => {
    headerElRef.current?.focus();
  }, [currentView]);

  const HEADER_STYLE: CSSObject = {
    width: '100%',
    zIndex: layout.HEADER_ZINDEX,
    // Adding the !important flag as this element needs to cary a pseudo-focus.
    // An inset box-shadow is used to draw the focus border globally, hence we want to override it here.
    boxShadow: 'none !important',

    [`@media (max-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      position: 'sticky',
      top: getPartnerHeaderHeight(),
    },
  };

  const CONTENT_PORTAL_STYLE: CSSObject = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: color.LOCAL_WHITE,

    [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      marginTop: 0,
      overflowY: 'auto',
    },
  };

  const FOOTER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    zIndex: layout.FOOTER_ZINDEX,

    [`@media (max-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
      position: 'sticky',
      bottom: 0,
    },
  };

  return (
    <Fragment>
      {/* Header portal */}
      <header
        css={HEADER_STYLE}
        tabIndex={-1}
        ref={(ref) => (headerElRef.current = ref)}
      />

      {/* Content portal */}
      <main css={CONTENT_PORTAL_STYLE} ref={(ref) => (mainElRef.current = ref)}>
        {componentHasRendered && getViewComponent()}
      </main>

      {/* Footer portal - used by the BottomSticky component for output */}
      <footer css={FOOTER_STYLE} ref={(ref) => (footerElRef.current = ref)} />
    </Fragment>
  );
});

export default View;
