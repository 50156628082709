import { useHistory } from 'react-router-dom';
import { useContext, useRef } from 'react';
import { IView } from '../views/view';
import useSessionStorage from './useSessionStorage';
import { AppContext } from '../contexts/appContext';
import { ViewContext } from '../contexts/viewContext';
import parseUri from '../utils/parseUri';

// Disable the automatic scroll restoration as it doesn't work
// properly when content is loaded asynchronously.
if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}

interface INavigationOptions {
  replace?: boolean;
}

interface IPostNavigationOptions {
  persistRequests?: boolean;
  internalUrl?: string;
  delta?: number;
}

// As we can't pass data through browser history when navigating backwards,
// this is a temporary container for passing options for that use case.
let postNavigationOptions: IPostNavigationOptions = {};

const useNavigation = () => {
  const { reinitializeApp } = useContext(AppContext);
  const { mainElement } = useContext(ViewContext);
  const history = useHistory();

  const cache = useSessionStorage<number>('SCROLL_POSITION', {
    locationAware: true,
  });

  const getViewStack = () => {
    return history.location.state as Array<IView>;
  };

  const getClosestModalRootIndex = (viewStack: Array<IView>) => {
    // Check if the current route is a modal. If that's not the case,
    // loop through the view stack and find the index of the closest modal.
    let closestModalIndex = -1;
    viewStack?.forEach((view, i) => {
      if (view?.isModalRoot) {
        closestModalIndex = i;
      }
    });
    return closestModalIndex;
  };

  const navigate = (view: IView, options?: INavigationOptions) => {
    // Reset post-navigation options.
    postNavigationOptions = {};

    // Store current scroll position.
    cache[1](window.scrollY || mainElement?.scrollTop || 0);

    const url = history.location.pathname + history.location.search;
    const currentStack = getViewStack() || [];
    const isModalDescendant = getClosestModalRootIndex(currentStack) > -1;

    if (options?.replace) {
      currentStack.pop();
      history.replace(url, [...currentStack, { ...view, isModalDescendant }]);
    } else {
      history.push(url, [...currentStack, { ...view, isModalDescendant }]);
    }
  };

  const push = (view: IView) => {
    return navigate(view);
  };

  const present = (view: IView) => {
    return navigate({ ...view, isModalRoot: true });
  };

  const replace = (view: IView) => {
    return navigate(view, { replace: true });
  };

  const replaceWithModal = (view: IView) => {
    return navigate({ ...view, isModalRoot: true }, { replace: true });
  };

  const redirectToPath = (path: string, includeRedirect?: boolean) => {
    const params = new URLSearchParams(history.location.search);
    const redirect = encodeURIComponent(params.get('redirect') || '');
    const shouldIncludeRedirect = includeRedirect && redirect;
    const url = `${path}${
      shouldIncludeRedirect ? `?redirect=${redirect}` : ''
    }`;

    if (!shouldIncludeRedirect && path.includes('call')) {
      // If flex call, reinitialize the app as there is no cleaner way to handle flex call.
      reinitializeApp();
      return;
    }
    if (
      !shouldIncludeRedirect &&
      history.location.pathname.includes('/app-link/')
    ) {
      // If the user is on a universal link, redirect to the screen with the universal link as a query parameter.
      const parsedUrl = parseUri(url);

      history.push(`/${parsedUrl.host}?${parsedUrl.query.toString()}`);
    } else {
      history.replace(url);
    }
  };

  const pop = (postNavOptions?: IPostNavigationOptions) => {
    // Update post-navigation options.
    postNavigationOptions = postNavOptions || {};

    const delta = postNavigationOptions?.delta || 1;
    const stackSize = getViewStack()?.length || 1;

    if (delta < stackSize) {
      history.go(delta * -1);
      return;
    }
    reinitializeApp(postNavigationOptions?.internalUrl);
  };

  const dismiss = (postNavOptions?: IPostNavigationOptions) => {
    // Update post-navigation options.
    postNavigationOptions = postNavOptions || {};

    const viewStack = getViewStack();
    const closestModalIndex = getClosestModalRootIndex(viewStack);

    // Amount of navigation steps to dismiss
    const targetNavigationLength = viewStack?.length - closestModalIndex;

    if (closestModalIndex > -1) {
      // If we're navigating out of bounds, relaunch the app.
      if (viewStack?.length - targetNavigationLength < 1) {
        reinitializeApp();
      } else if (viewStack) {
        history.go((viewStack?.length - closestModalIndex) * -1);
      }
    }
  };

  const canPop = () => {
    const stack = getViewStack();
    const closestModalIndex = getClosestModalRootIndex(stack);
    const currentViewIsNotModalRoot = closestModalIndex !== stack.length - 1;

    return stack.length > 1 && currentViewIsNotModalRoot;
  };

  const getPostNavigationOptions = () => {
    return postNavigationOptions;
  };

  const getCurrentView = () => {
    const stack = getViewStack();
    return stack && stack[stack.length - 1];
  };

  const getLocationKey = () => {
    return history.location.key;
  };

  return useRef({
    push,
    present,
    replace,
    replaceWithModal,
    redirectToPath,
    pop,
    dismiss,

    canPop,

    reinitializeApp,

    getCurrentView,
    getLocationKey,
    getPostNavigationOptions,
  }).current;
};

export default useNavigation;
